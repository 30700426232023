import { format } from 'date-fns'
import { Link } from 'gatsby'
import React from 'react'
import { buildImageObj, cn, getBlogUrl } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import PortableText from './portableText'

import styles from './blog-post-preview.module.css'
import { responsiveTitle2 as responsiveTitle } from './typography.module.css'

function BlogPostPreview(props) {
  return (
    <Link
      className={props.isInList ? styles.inList : styles.inGrid}
      to={getBlogUrl(props.publishedAt, props.slug.current)}
    >
      <div className={styles.text}>
        <h3 className={cn(responsiveTitle, styles.title)}>{props.title}</h3>
          <div className={styles.excerpt}>
          {props._rawExcerpt && (<PortableText blocks={props._rawExcerpt} />)} more...
          </div>
        <div className={styles.date}>{format(props.publishedAt, 'MMMM Do, YYYY')}</div>
      </div>
      {props.mainImage && props.mainImage.asset && (
        <div className={styles.leadMediaThumb}>
          <img
            src={imageUrlFor(buildImageObj(props.mainImage))
              .width(600)
              .auto('format')
              .url()}
            alt={props.mainImage.alt}
          />
        </div>
      )}
    </Link>
  )
}

export default BlogPostPreview
